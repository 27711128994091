import companyPresentation from '@images/wizard/goals/companyPresentation.png'
import contestSignup from '@images/wizard/goals/contestSignup.png'
import eventSignup from '@images/wizard/goals/eventSignup.png'
import mobileAppPromotion from '@images/wizard/goals/mobileAppPromotion.png'
import newsletterSignup from '@images/wizard/goals/newsletterSignup.png'
import offerDownload from '@images/wizard/goals/offerDownload.png'
import products from '@images/wizard/goals/products.png'
import productSale from '@images/wizard/goals/productSale.png'
import recruitment from '@images/wizard/goals/recruitment.png'
import resourceDownload from '@images/wizard/goals/resourceDownload.png'
import saasRegistration from '@images/wizard/goals/saasRegistration.png'
import scheduleMeeting from '@images/wizard/goals/scheduleMeeting.png'
import services from '@images/wizard/goals/services.png'
import serviceSale from '@images/wizard/goals/serviceSale.png'
import waitlist from '@images/wizard/goals/waitlist.png'
import webinarSignup from '@images/wizard/goals/webinarSignup.png'

const COMPANY_PRESENTATION = 'companyPresentation'
const WAITLIST = 'waitlist'
const OFFER_DOWNLOAD = 'offerDownload'

export const secondStepOptions = [
  { name: COMPANY_PRESENTATION, image: companyPresentation },
  { name: 'contestSignup', image: contestSignup },
  { name: 'eventSignup', image: eventSignup },
  { name: 'mobileAppPromotion', image: mobileAppPromotion },
  { name: 'newsletterSignup', image: newsletterSignup },
  { name: OFFER_DOWNLOAD, image: offerDownload },
  { name: 'productSale', image: productSale },
  { name: 'recruitment', image: recruitment },
  { name: 'resourceDownload', image: resourceDownload },
  { name: 'saasRegistration', image: saasRegistration },
  { name: 'scheduleMeeting', image: scheduleMeeting },
  { name: 'serviceSale', image: serviceSale },
  { name: WAITLIST, image: waitlist },
  { name: 'webinarSignup', image: webinarSignup }
]

export const thirdStepOptions = [
  { name: 'products', image: products },
  { name: 'services', image: services }
]

export const optionsWithAccessToThirdStep = [
  COMPANY_PRESENTATION,
  WAITLIST,
  OFFER_DOWNLOAD
]
