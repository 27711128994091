import { CurrencyType } from '@constants/currencies'

const voucherValue = {
  PLN: 'infobar.free.plans.trigger.voucher.pln',
  USD: 'infobar.free.plans.trigger.voucher.usd',
  EUR: 'infobar.free.plans.trigger.voucher.eur',
  GBP: 'infobar.free.plans.trigger.voucher.gbp',
  BRL: 'infobar.free.plans.trigger.voucher.brl'
}

export const getVoucherValue = (currency: CurrencyType) => {
  return voucherValue[currency]
}
