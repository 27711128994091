import { CurrencyType } from '@constants/currencies'

const badgeContent = {
  PLN: 'infobar.free.plans.trigger.badge.pln',
  USD: 'infobar.free.plans.trigger.badge.usd',
  EUR: 'infobar.free.plans.trigger.badge.eur',
  GBP: 'infobar.free.plans.trigger.badge.gbp',
  BRL: 'infobar.free.plans.trigger.badge.brl'
}

export const getBadgeContent = (currency: CurrencyType) => {
  return badgeContent[currency]
}
