import { useAcl } from '@contexts/acl'
import { useUserContext } from '@contexts/user'
import { PROMOTION_FOR_FREE_PLAN_START_DAY } from '@hooks/useSale'
import {
  Badge,
  Button,
  CountdownTimer,
  Image,
  Paragraph,
  Spacer,
  Spreader,
  useCountdown
} from '@ui-kit'
import moment from 'moment-timezone'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import styles from './FreePlansTriggerToUpgrade.module.scss'
import { getBadgeContent, getCorrectImage, getVoucherValue } from './helpers'

export const FreePlansTriggerToUpgrade = () => {
  const { t } = useTranslation()

  const { accountCurrency, language } = useUserContext()

  const hasAccessToChangePlan = useAcl('plan.change_plan')

  const navigate = useNavigate()

  const handleRedirectToPricing = () => {
    navigate('/account/package')
  }

  // TODO change in on real date
  const countDownArgs = {
    startDate: PROMOTION_FOR_FREE_PLAN_START_DAY,
    endDate: moment(PROMOTION_FOR_FREE_PLAN_START_DAY)
      .add(7, 'days')
      .format('YYYY-MM-DD')
  }

  const { isFinished } = useCountdown(countDownArgs)

  const badgeContent = getBadgeContent(accountCurrency)

  const image = getCorrectImage(language || 'en')

  const voucherValue = t(getVoucherValue(accountCurrency))

  if (isFinished) {
    return null
  }

  return (
    <Fragment>
      <Row alignItems='center'>
        <Image src={image} alt='Free Plans' />

        <Spreader spread={15} />

        <Column>
          <Paragraph>
            <Trans
              i18nKey='infobar.free.plans.trigger.header'
              components={{
                b: <b />
              }}
            />

            <Spreader spread={15} />

            <Badge variant='success-filled' weight={600}>
              {t(badgeContent)}
            </Badge>
          </Paragraph>

          <Spacer space={5} />

          <Paragraph size={12}>
            <Trans
              i18nKey='infobar.free.plans.trigger.paragraph'
              components={{
                promo: <span className={styles.promo} />
              }}
              values={{
                voucherValue
              }}
            />
          </Paragraph>
        </Column>
      </Row>

      <Row className={styles.rightColumn}>
        <Fragment>
          {/* TODO add real start and end date */}
          <CountdownTimer {...countDownArgs} variant='success' />

          {hasAccessToChangePlan && (
            <Fragment>
              <Spreader spread={15} />

              <Button onClick={handleRedirectToPricing}>
                <Trans i18nKey='infobar.free.plans.button.compare.plans' />
              </Button>
            </Fragment>
          )}
        </Fragment>
      </Row>
    </Fragment>
  )
}

FreePlansTriggerToUpgrade.displayName = 'TopbarInfobarFreePlansTriggerToUpgrade'
