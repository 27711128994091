import { AlertTypes } from '@components/Topbar/components/Infobars/constants'
import styles from '@components/Topbar/components/Infobars/Infobars.module.scss'
import { useStyles } from '@hooks/useStyles'
import { Alert } from '@landingi/landingi-ui-kit'
import { FC } from 'react'
import { Row } from 'simple-flexbox'

interface InfobarAlertProps {
  type: AlertTypes
  component: () => JSX.Element
  hideIcon?: boolean
  paddingTopBottom?: boolean
}

export const InfobarAlert: FC<InfobarAlertProps> = ({
  type,
  component: Component,
  hideIcon = false,
  paddingTopBottom = true
}) => {
  const infobarStyles = useStyles({
    [styles.alert]: true,
    [styles['alert__no-top-bottom-padding']]: !paddingTopBottom
  })

  return (
    <Alert className={infobarStyles} type={type} hideIcon={hideIcon}>
      <Row justifyContent='space-between' alignItems='center'>
        <Component />
      </Row>
    </Alert>
  )
}

InfobarAlert.displayName = 'TopbarInfobarsInfobarAlert'
