import {
  CREDIT_CARD_METHOD,
  INVOICE_METHOD,
  PAYPAL_METHOD,
  PRZELEWY24_METHOD
} from '@constants/paymentMethods'
import { useFeatureFlags } from '@contexts/featureFlags'
import { getCard } from '@helpers/getCard'
import { cardNumberMask, dateMask } from '@helpers/masks'
import ImagePaypal from '@images/registration/paypal.svg'
import ImagePrzelewy24 from '@images/registration/przelewy24.svg'
import {
  BoxOutline,
  InputForm,
  MaskedInputForm,
  Radio
} from '@landingi/landingi-ui-kit'
import CardImages from '@pages/Authentication/components/CreditCard/CardImages'
import CVVTooltipContent from '@pages/Authentication/components/CreditCard/CVVTooltipContent'
import { useCreditCardStepContext } from '@pages/Authentication/contexts/creditCardStep'
import styles from '@pages/Authentication/routes/CreditCardStep/CreditCardStep.module.scss'
import { Heading, Icon, Image, Paragraph, Spacer, Spreader } from '@ui-kit'
import {
  getCvvPlaceholderTranslationID,
  getTooltipImageURL
} from '@validations/CreditCard'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

const PaymentMethod = () => {
  const { t } = useTranslation()

  const {
    formik: { values, handleChange, handleBlur, errors, touched }
  } = useCreditCardStepContext()

  const hasAccessToNewPaymentMethod =
    useFeatureFlags('NEW_REGISTER_PAGE') && values?.country?.value === 'PL'

  const { method, cardNumber, expirationDate, cvv } = values

  const card = getCard(cardNumber)

  return (
    <BoxOutline variant='background' disableHover padding='large'>
      <div className={styles['payment-method__content']}>
        <Heading level={3} weight={600}>
          {t('registration.flow.credit.card.step.your.payment.method')}
        </Heading>

        <Spacer space={20} />

        <Column>
          <div className={styles['credit-card-radio']}>
            <div className={styles['payment-method-radio']}>
              <Radio
                className={styles['payment-method-radio__input']}
                field={{
                  name: 'method',
                  value: method,
                  onChange: handleChange
                }}
                label={
                  <Row alignItems='center'>
                    {t('subscription.credit.card.label')}

                    <Spreader spread={5} />

                    <CardImages cardNumber={cardNumber} />
                  </Row>
                }
                color='color-1'
                size='small'
                id={CREDIT_CARD_METHOD}
                form={{ errors }}
              />
            </div>
          </div>

          {method === CREDIT_CARD_METHOD && (
            <Fragment>
              <Spacer space={30} />

              <div className={styles['credit-card-data']}>
                <Column className={styles['credit-card-data__number']}>
                  <MaskedInputForm
                    className={styles['cc-input']}
                    id='cardNumber'
                    field={{
                      name: 'cardNumber',
                      value: cardNumber,
                      onChange: handleChange,
                      onBlur: handleBlur
                    }}
                    form={{
                      errors,
                      touched
                    }}
                    mask={cardNumberMask}
                    i18n={{
                      placeholder: t('subscription.card.placeholder'),
                      label: t('registration.flow.credit.card.step.card.number')
                    }}
                    alwaysShowLabel
                  />
                </Column>

                <Spreader />

                <Row>
                  <Column
                    className={styles['credit-card-data__expiration-date']}
                  >
                    <MaskedInputForm
                      className={styles['cc-input']}
                      id='expirationDate'
                      field={{
                        name: 'expirationDate',
                        value: expirationDate,
                        onChange: handleChange,
                        onBlur: handleBlur
                      }}
                      form={{
                        errors,
                        touched
                      }}
                      mask={dateMask}
                      type='text'
                      i18n={{
                        placeholder: t(
                          'subscription.expiration.date.placeholder'
                        ),
                        label: t(
                          'registration.flow.credit.card.step.expiry.date'
                        )
                      }}
                      guide
                      alwaysShowLabel
                    />
                  </Column>

                  <Spreader />

                  <Column className={styles['credit-card-data__cvv']}>
                    <InputForm
                      className={styles['cc-input']}
                      field={{
                        name: 'cvv',
                        value: cvv,
                        onChange: handleChange,
                        onBlur: handleBlur
                      }}
                      id='cvv'
                      form={{
                        errors,
                        touched
                      }}
                      i18n={{
                        placeholder: t(getCvvPlaceholderTranslationID(card)),
                        label: t('registration.flow.credit.card.step.cvv')
                      }}
                      background='transparent'
                      type='password'
                      tooltip={
                        <CVVTooltipContent image={getTooltipImageURL(card)} />
                      }
                      alwaysShowLabel
                    />
                  </Column>
                </Row>
              </div>

              <Spacer />

              <Paragraph size={12}>
                <Icon icon='icon-info-circle' />

                <Spreader spread={5} />

                <Trans i18nKey='registration.flow.credit.card.step.your.card.will.be.authorized' />
              </Paragraph>
            </Fragment>
          )}

          <Spacer space={20} />

          <Radio
            className={styles['payment-method-radio__input']}
            field={{
              name: 'method',
              value: method,
              onChange: handleChange
            }}
            label={<Image src={ImagePaypal} />}
            color='color-1'
            size='small'
            id={PAYPAL_METHOD}
            form={{ errors }}
          />

          {hasAccessToNewPaymentMethod && (
            <Fragment>
              <Spacer space={20} />

              <Radio
                className={styles['payment-method-radio__input']}
                field={{
                  name: 'method',
                  value: method,
                  onChange: handleChange
                }}
                label={<Image src={ImagePrzelewy24} />}
                color='color-1'
                size='small'
                id={PRZELEWY24_METHOD}
                form={{ errors }}
              />

              {method === PRZELEWY24_METHOD && (
                <Fragment>
                  <Spacer space={20} />

                  <Paragraph>
                    <Trans i18nKey='registration.flow.credit.card.step.przelewy24.method.description' />
                  </Paragraph>
                </Fragment>
              )}

              <Spacer space={20} />

              <Radio
                className={styles['payment-method-radio__input']}
                field={{
                  name: 'method',
                  value: method,
                  onChange: handleChange
                }}
                label={t('registration.flow.credit.card.step.invoice.method')}
                color='color-1'
                size='small'
                id={INVOICE_METHOD}
                form={{ errors }}
              />

              {method === INVOICE_METHOD && (
                <Fragment>
                  <Spacer space={20} />

                  <Paragraph>
                    <Trans i18nKey='registration.flow.credit.card.step.invoice.method.description' />
                  </Paragraph>
                </Fragment>
              )}
            </Fragment>
          )}

          <Spacer />
        </Column>
      </div>
    </BoxOutline>
  )
}

PaymentMethod.displayName = 'PaymentMethod'

export default PaymentMethod
