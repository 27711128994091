import { FreePlans } from '@components/Topbar/components/Infobars/components/FreePlans'
import { FreePlansTriggerToUpgrade } from '@components/Topbar/components/Infobars/components/FreePlansTriggerToUpgrade'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'

import { AlertTypes, INFOBAR_TYPE } from '../constants'

export const useGetFreePlansType = (): {
  type: AlertTypes
  component: () => JSX.Element
  hideIcon?: boolean
  paddingTopBottom?: boolean
} | null => {
  const { user } = useUserContext()

  const hasFreePlanRestrictions = useFeatureFlags('FREE_PLAN_RESTRICTIONS')
  const hasAccessToPromotionForFreePlan = useFeatureFlags(
    'PROMOTION_FOR_FREE_PLAN'
  )

  const isInSQLStage = user?.platform_stage === 'SQL'

  if (hasFreePlanRestrictions) {
    if (hasAccessToPromotionForFreePlan && isInSQLStage) {
      return {
        type: INFOBAR_TYPE.INFO,
        component: () => {
          return <FreePlansTriggerToUpgrade />
        },
        hideIcon: true,
        paddingTopBottom: false
      }
    }

    if (user?.display_free_trial_infobar) {
      return {
        type: INFOBAR_TYPE.WARNING,
        component: () => {
          return <FreePlans />
        }
      }
    }
  }

  return null
}
