import FreePlansTriggerEng from '@images/infobars/free_trial_trigger_infobar_eng.png'
import FreePlansTriggerPl from '@images/infobars/free_trial_trigger_infobar_pl.png'
import FreePlansTriggerPt from '@images/infobars/free_trial_trigger_infobar_pt.png'

export const getCorrectImage = (language: string) => {
  switch (language) {
    case 'en':
      return FreePlansTriggerEng
    case 'pl':
      return FreePlansTriggerPl
    case 'pt':
      return FreePlansTriggerPt
    default:
      return FreePlansTriggerEng
  }
}
